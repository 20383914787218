<template>
  <div class="box">
    <i class="iconfont icon-email"></i>
    <h3>{{ $t('查看电子邮件') }}</h3>
    <span class="desc">{{ $t('请检查邮箱的中最新的邮件以获取重置密码链接') }}</span>
    <span class="msg">{{msg}}</span>
    <button @click="resend()">{{ $t('重新发送邮件') }}</button>
  </div>
</template>

<script>
  import {
    notifyPassword
  } from '../../config/api.js'
  export default {
    data() {
      return {
        userEmail: '',
        msg: ''
      }
    },
    mounted() {
      this.userEmail = this.$route.params.email
      console.log(this.userEmail)
    },
    methods: {
      resend() {
        let that = this
        notifyPassword({
          "username": this.userEmail
        }).then(res => {
          that.msg = this.$t('发送成功')
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  // @import '../../assets/styles/varibles.styl'

  .box {
    width: 100%;
    margin: 0;
    text-align: center;
    padding-top: 8%;

    .icon-email {
      font-size: 8rem;
      display: block;
      margin: 1% auto 4rem auto;
      color: #10a37f;
      padding: 1.7rem;
    }

    h3 {
      margin-bottom: 2rem;
      font-size: 24px;
      line-height: 1.2;
    }

    .desc{
      width: 25%;
      display: block;
      margin: 0 auto;
      font-size: 14px;
      line-height: 1.2;
    }

  .msg {
    width: 25%;
    display: block;
    margin: 1rem auto 0 auto;
    color: var(--theme-color);
  }

  button {
    background-color: white;
    color: black;
    font-size: 14px;
    border: 1px solid #c2c8d0;
    outline: none;
    border-radius: 3px;
    height: 52px;
    width: 25%;
    margin: 3rem 0;
  }

  button:hover {
    background-color: #c2c8d0;
  }

  }

  @media screen and (max-width: 768px) {
    .box {

      .desc,
      button {
        width: 80% !important;
      }
    }
  }
</style>